.KidProLoginTitleBox{
    text-align: center;
}
.KidProLoginTitle{
    /* font-size: 3.5rem; */
    font-size: 48px;
    color: #1E8C96;
    font-weight: bold;

}
.subTitle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    /* font-size: 20px; */
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    /* color: #585858; */
    color: #1E8C96;
    margin-bottom: 4px;
  
}
.subTitle2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: #585858;
}
.directSec{
    font-weight: 600;
    font-family: 'Inter';
    font-style: normal;
    color: #FA9600;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 1rem;
}
/* .directtoNoline{
    font-weight: 300;
    font-size: 24px;
    line-height: 143.52%;
}
.directtoNoline:hover{
    color: #F26419;
}
.directtoLogin{
    font-weight: 300;
    font-size: 24px;
    line-height: 143.52%;
}
.directtoLogin:hover{
    color: #F26419;
} */

/* 附加連結 */
.directtoNoline,.directtoLogin {
    font-size: 16px;
    font-weight: 600;
    color: #FA9600;
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
}
.directtoNoline:hover, .directtoLogin:hover {
    color: #F26419;
    cursor: pointer;
    text-decoration: underline;
}

.LINE{
    box-sizing: border-box;
    margin: 1rem auto 2rem auto;
    width: fit-content;
    text-align: center;
    cursor: pointer;
}
.LINEimg{
    width: 93%;
}

@media screen and (max-width:540px){
    .KidProLoginTitle {
        font-size: 36px;
    }
    .subTitle{
        font-size: 24px;
    }
    .subTitle2{
        font-size: 14px;
    }
    .LINE{
        width: 200px;
    }
    .directtoNoline{
        font-weight: 600;
        font-size: 16px;
        line-height: 120.52%;

    }
    .directtoLogin{
        font-weight: 600;
        font-size: 16px;
        line-height: 120.52%;
    }

}