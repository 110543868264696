/* 錯誤訊息區塊 */
.RegxpErrorSec{
    width: 100%;
    text-align: center;
}
/* 錯誤訊息 */
.RegxpError{
    margin: 12px!important;
    color: #F26419;
}
/* Popup 整個內容區塊 */
.modalContainer{
    width: fit-content;
    height: fit-content;
    margin: 35%;
}
.modal{
    background-color: #FFF096;
    border-radius: 3px;
}
.modalBody{
    padding: 18px!important;
    height: 80px!important; 
}
@media screen and ( max-width: 768px ) {
    /* Popup 整個內容區塊 */
    .modalContainer{
        width: fit-content;
        height: fit-content;
        margin: 25% 50%;
    }
}