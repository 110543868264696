/* 按鈕右邊界 */
.loginButton{
    margin-right: 1em;
}

/* 給AutoPopup使用，提示標題樣式 */
.modalTitle{
    width: 100%;
    margin-left: 1.2em;
    text-align: center;
    font-size: 1.2em;
    color: #FA9600;
}
/* Popup 按鈕 - 登入/註冊文字 */
.loginText {
    color: #666;
}
.loginText:active {
    color: #eee;
}
/* Popup 整個內容區塊 */
.modal {
    background-color: white;
    border-radius: 3px;
}


/* Popup Header層 */
.modalHeader{
    /* padding: 8px; */
    border-width: 0;
    border: none !important;
    width: 100%;
    border-radius: 3px;
}
/* .modalClose{
    position: absolute;
    font-weight: 700;
    color: white;
    font-size: 32px;
    top: -42px;
    cursor: pointer;
} */
.modalClose{
    font-size: 28px;
    font-weight: 700;
    color: #FFF;
    background-color: #1E8C96CC;
    border-radius: 5px;
    box-shadow: antiquewhite;
    box-shadow: 0px 0px 5px #eee;
    position: absolute;
    top: 2px;
    right: 2px;
    width: 32px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
} 
.PopupBanner{
    border-radius: 3px;
    width: 100%;
    height: 100%;

}
.PopupBanner img{
    border-radius: 3px;
    width: 100%;
    height: 100%;
}
.modalBody{
    padding: 18px!important;
    background-color: white;
}


